<template>
    <div >
        <div v-if="languageSelector" class="max-w-screen-mobile mx-auto h-screen  py-2 text-center realtive">
            <Header :language="lang" noPin/>
            <div class="text-center mx-6 h-3/5 flex flex-col justify-center">
                <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white">{{ $t('language.selectTitle') }}</h4>
                <Language flag mobile :language="lang" @langchanged="langchanged_" class="my-4" v-on:click="selectorChange"/>
                <p class="font-body text-bodyL text-sepiaBlack dark:text-white px-6">{{ $t('language.text') }}</p>
            </div>
            <!-- <Button :text="$t('language.saveBtn')" buttonType="primary" fullWidth class="absolute bottom-8 inset-x-12" v-on:click="selectorChange"/> -->
        </div>
        <div v-else class="max-w-screen-mobile mx-auto h-screen py-2 relative">
            <Header :language="lang" 
                @langchanged="langchanged_"
                noPin>
            </Header>
            <div v-if="btnDisabel">
                <Loader />
            </div>
            <div class="mx-6 text-center">
                <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white pt-14">{{ $t('login.register') }}</h4>
                <p class="font-body text-bodyM text-sepiaBlack dark:text-white pt-8 regtext">{{ $t('login.registerText') }}</p>
                <div class="hidden mx-auto" id="registerError">
                    <SystemMessage :text="$t('login.quickLoginError')" type='error' />
                </div>
                <Input :placeholder="$t('login.email')" type="email" name="email" id="email" required />
                <Input :placeholder="$t('login.name')" type="text" id="name" name="name"/>
                <p class="font-body text-bodyS text-redDamask dark:text-redDamask pt-9 pb-4 termstext">{{ $t('login.policyText1') }} 
                    <a href="#" class="text-halfColonialWhite underline" @click="openModal">{{ $t('login.cookiePolicy') }} </a> {{ $t('login.policyText2') }}</p>

                <Button :disabledBtn="btnDisabel" xl :text="stringTranslations.register" buttonType="primary" fullWidth v-on:click="quickLogin" />
                <Button href="/login" :disabledBtn="btnDisabel" xl :text="stringTranslations.login" buttonType="secondary" fullWidth />
            </div>
            <Modal class="hidden" />
        </div>
    </div>
</template>

<script>

import Header from "@/components/mobile/Header.vue";
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue';
import Modal from '@/components/mobile/Modal.vue';
import Language from '@/components/Language.vue';
import SystemMessage from '@/components/SystemMessage.vue';
import Loader from '@/components/Loader.vue';

export default {
    name: "QuickLogin",
    components: {
        Header,
        Button,
        Input,
        Modal,
        Language,
        SystemMessage,
        Loader
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            email: '',
            name: '',
            btnDisabel: false,
            languageSelector: true,
        }
    },
    computed: {
        stringTranslations() {
            return {
                register: this.$t('login.register'),
                login: this.$t('login.login'),
            }
        }
    },
    mounted() {
        this.$i18n.locale = this.lang;
        this.logedinCheck();
    },
    methods: {
        quickLogin() {
            document.querySelector("#systemM").classList.remove("hidden");
            document.querySelector("#registerError").classList.add("hidden");
            this.btnDisabel = true;
            this.email = document.querySelector('#email').value;
            this.name = document.querySelector('#name').value;
            if (!this.name || this.name == '') {
                document.querySelector("#registerError").classList.remove("hidden");
                this.btnDisabel = false;
            } else {
                const variabels = {language: this.lang, email: this.email, name: this.name};
                this.axios.post(this.baseURL + `/wp-json/wp/v2/quick-login`, variabels, {})
                .then(response => {
                    const response_data = response.data;
                    if (response_data.error) {
                        document.querySelector("#registerError").classList.remove("hidden");
                        this.btnDisabel = false;
                    } else {
                        const login_user = {username: response_data.email, password: response_data.pin, name: response_data.name}
                        try{
                            const loginResult = this.$store.dispatch('login', login_user);
                            loginResult.then(() => {
                                this.$router.push('/thank-you');
                                this.btnDisabel = false;
                            }).catch(() => {
                                this.btnDisabel = false;
                                document.querySelector("#registerError").classList.remove("hidden");
                            });
                        }catch(e){
                            document.querySelector("#registerError").classList.remove("hidden");
                            this.btnDisabel = false;
                        }
                    }
                        
                }).catch( () => {
                    this.btnDisabel = false;
                });
            }
            
        },
        
        langchanged_(value) {
            this.lang = value;
        },
        openModal() {
            // document.querySelector("#app").classList.add("opacity-30");
            // document.querySelector("#app").classList.add("dark:opacity-30");
            // document.querySelector("#popupModal").classList.add("opacity-100");
            // document.querySelector("#popupModal").classList.add("dark:opacity-100");
            document.querySelector("#popupModal").classList.remove("hidden");
        },
        selectorChange() {
            this.languageSelector = false;
        },
        logedinCheck() {
			const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
  			if (authUser) {
        		this.$router.push('/mobile/menu');
			}
		}
    },
};
</script>
